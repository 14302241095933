.App {
  margin: 7rem 2rem;
}

@media only screen and (min-width: 580px) {
  .App {
    margin: 4rem 2rem;
  }
}

@media only screen and (min-width: 1200px) {
  .App {
    margin-top: 3rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
  }
}
