.option-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  height: 100%;
  width: 100%;
  position: fixed;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.option-base {
  border-radius: 5px;
  background-color: var(--sec-bkg-clr);
  width: 100%;
  max-width: 300px;
}

.option-header {
  margin: 1.5rem;
  margin-bottom: 0.5rem;
}
.option-body {
  margin: 1rem 1.5rem;
  margin-bottom: 0.5rem;
}
.option-footer {
  margin: 1.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.option-button {
  color: var(--blk-clr);
  margin-right: 0.5rem;
  cursor: pointer;
  padding: 0.6rem 0.75rem;
  border-radius: 5px;
  border: none;
  background-color: var(--prim-clr);
}

.option-button:hover {
  background-color: var(--sec-clr);
}

.delete {
  background-color: var(--err-clr);
}

.save {
  background-color: var(--suc-clr);
}
