.family {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.family-heading--container {
  max-width: 400px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  background-color: var(--sec-bkg-clr);
  padding: 1.5rem;
  margin-bottom: 2rem;
  border: solid 2px var(--prim-clr);
  border-radius: 1rem;
}

.family-heading {
  color: var(--prim-clr);
  font-size: 3rem;
  margin-bottom: 1rem;
  align-self: center;
}

.family-heading--caption {
  line-height: 2rem;
  word-wrap: break-word;
  font-weight: 400;
}

.family-img {
  width: 100%;
  max-width: 400px;
  max-height: 400px;
  height: 50vh;
  border: solid 2rem var(--sec-bkg-clr);
  object-fit: cover;
  object-position: top;
}

@media only screen and (min-width: 500px) {
  .family-img {
    height: 75vh;
  }
}

@media only screen and (min-width: 700px) {
  .family {
    flex-direction: row;
  }

  .family-img {
    width: 50%;
    max-height: 500px;
  }

  .family-heading--container {
    height: 500px;
    margin-right: 2rem;
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 900px) {
  .family-heading--container {
    max-width: 400px;
  }
}
