.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error-message {
  background-color: var(--err-clr);
  padding: 1rem;
  border-radius: 5px;
  color: var(--wht-clr);
}

.empty {
  visibility: hidden;
}

.login-icon {
  margin-bottom: 1rem;
  max-width: 400px;
  max-height: 400px;
}

.login-title {
  margin-bottom: 1rem;
  text-align: center;
}

.login-form {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
}

.login-password {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  background-color: var(--sec-bkg-clr);
}

.login-password-label {
}

.login-password-input {
  width: 100%;
  font-size: 2rem;
  border-radius: 5px;
  outline: none;
  border: none;
  background-color: var(--sec-bkg-clr);
}

.login-vision {
  cursor: pointer;
  padding: 0.25rem;
  border-radius: 100rem;
}

.login-vision:hover {
  background-color: var(--prim-clr);
}

.login-button {
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  font-size: 1rem;
  border-radius: 5px;
  outline: none;
  border: none;
  background-color: var(--prim-clr);
  cursor: pointer;
  color: var(--blk-clr);
}

.login-button:hover {
  background-color: var(--sec-clr);
}

.login-persist {
  display: flex;
  align-items: center;
}

.login-persist-check {
  height: 20px;
  width: 20px;
  outline: none;
  border: none;
  margin-right: 0.5rem;
}

.login-persist-check[type='checkbox'] {
  position: relative;
  cursor: pointer;
}
.login-persist-check[type='checkbox']:before {
  content: '';
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  border: 2px solid var(--prim-clr);
  border-radius: 3px;
  background-color: white;
}
.login-persist-check[type='checkbox']:checked:after {
  content: '';
  display: block;
  width: 5px;
  height: 10px;
  border: solid var(--prim-clr);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 2px;
  left: 6px;
}

.login-persist-text {
}
