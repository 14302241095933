.imagepreview--wrapper {
  display: inline-block;
  position: relative;
}

.imagepreview--close {
  position: absolute;
  top: 4px;
  right: 4px;
  background-color: var(--prim-clr);
  padding: 0.2rem;
  border-radius: 100%;
  height: 28px;
  width: 28px;
  cursor: pointer;
}

.imagepreview--close:hover {
  background-color: var(--sec-bkg-clr);
}

.imagepreview {
  height: 95px;
  width: 95px;
  border-radius: 5px;
  border: solid 2px var(--prim-clr);
  object-fit: cover;
}
