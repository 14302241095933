.modal--wrapper {
  display: flex;
  z-index: 1000;
  height: 100%;
  width: 100%;
  position: fixed;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.modal--close {
  position: absolute;
  top: 1rem;
  left: 1rem;
  height: 50px;
  width: 50px;
  cursor: pointer;
  padding: 0.4rem;
  border-radius: 100%;
  background-color: var(--prim-clr);
}

.modal--close:hover {
  background-color: var(--sec-clr);
}

.modal--image {
  justify-self: center;
  align-self: center;
  margin: auto;
  height: 90%;
  width: 100%;
  object-fit: contain;
}
