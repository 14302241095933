.form {
  margin-bottom: 1rem;
  width: 100%;
  max-width: 550px;
}
.form-header {
  background-color: var(--sec-bkg-clr);
  border: 2px solid var(--prim-clr);
  border-radius: 5px;
  padding: 0.25rem 0.5rem;
  display: flex;
  align-items: center;
  max-width: 5500px;
  margin-bottom: 0.25rem;
}

.form-pfp {
  height: 65px;
  width: 65px;
  object-fit: cover;
  border-radius: 100rem;
  border: 2px solid var(--prim-clr);
}

.form-username {
  margin-left: 0.75rem;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  background-color: var(--prim-clr);
  color: var(--blk-clr);
  padding: 0.25rem;
  outline: none;
}

.form-body {
  max-width: 550px;
  padding: 1rem 2rem;
  margin-bottom: 0.25rem;
  background-color: var(--sec-bkg-clr);
  border: 2px solid var(--prim-clr);
  border-radius: 5px;
}

.form-textarea {
  width: 100%;
  font-size: 1rem;
  word-wrap: break-word;
  line-height: 2rem;
  resize: none;
  outline: none;
  background-color: var(--sec-bkg-clr);
  border: none;
  overflow-y: auto;
  margin-bottom: 1rem;
}

.form--textarea-images {
  margin-bottom: 2rem;
}

.form-textarea-previewimages {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.form-footer {
  max-width: 550px;
  padding: 0.5rem;
  background-color: var(--sec-bkg-clr);
  border: 2px solid var(--prim-clr);
  border-radius: 5px;
  display: flex;
  justify-content: flex-end;
}

.form-images {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 0.25rem;
  height: 3rem;
  width: 3rem;
  background-color: var(--prim-clr);
  border-radius: 5px;
}

.form-icon {
  height: 30px;
  width: 30px;
}

input[type='file'] {
  display: none;
}

.form-submit {
  border: none;
  border-radius: 5px;
  background: var(--prim-clr);
  height: 48px;
  width: 54px;
  color: var(--blk-clr);
}

.form-submit:hover,
.form-images:hover {
  cursor: pointer;
  background-color: var(--sec-clr);
}

[disabled],
[disabled]:hover {
  background: darkgray;
  cursor: not-allowed !important;
}

.lds-heart {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
  z-index: 999;
  display: inline-block;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0px;
  margin: auto;
  width: 80px;
  height: 80px;
  transform: rotate(45deg);
  transform-origin: 40px 40px;
}
.lds-heart div {
  top: 32px;
  left: 32px;
  position: absolute;
  width: 32px;
  height: 32px;
  background: var(--prim-clr);
  animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}
.lds-heart div:after,
.lds-heart div:before {
  content: ' ';
  position: absolute;
  display: block;
  width: 32px;
  height: 32px;
  background: var(--prim-clr);
}
.lds-heart div:before {
  left: -24px;
  border-radius: 50% 0 0 50%;
}
.lds-heart div:after {
  top: -24px;
  border-radius: 50% 50% 0 0;
}
@keyframes lds-heart {
  0% {
    transform: scale(0.95);
  }
  5% {
    transform: scale(1.1);
  }
  39% {
    transform: scale(0.85);
  }
  45% {
    transform: scale(1);
  }
  60% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.9);
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
