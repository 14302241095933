.home {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-image {
  width: 100%;
  height: 50vh;
  object-fit: cover;
  object-position: 0px 0px;
  border: solid 2rem var(--sec-bkg-clr);
}

.home-title {
  margin: 1rem;
  font-size: 3rem;
  color: var(--prim-clr);
  align-self: flex-start;
}

.home-title--caption {
  margin: 1rem;
  font-size: 1.85rem;
  align-self: flex-start;
}

@media only screen and (min-width: 500px) {
  .home-title {
    font-size: 2.5rem;
  }

  .home-image {
    width: 80%;
  }

  .home-title--caption {
    font-size: 2rem;
  }
}

@media only screen and (min-width: 750px) {
  .home-title {
    font-size: 3rem;
  }

  .home-title--caption {
    font-size: 2.15rem;
  }
}

@media only screen and (min-width: 1000px) {
  .home-image {
    width: 65%;
  }
}

@media only screen and (min-width: 1200px) {
  .home-image {
    width: 50%;
  }

  .home-title {
    font-size: 3.5rem;
  }

  .home-title--caption {
    font-size: 2.25rem;
  }
}

@media only screen and (min-width: 1200px) {
  .home-image {
    width: 60%;
  }
}
