* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --prim-clr: #ffbfb0;
  --sec-clr: #ffe0d9;
  --sec-bkg-clr: #fffafa;
  --prim-bkg-clr: #fef5d8;
  --blk-clr: #000000;
  --wht-clr: #ffffff;
  --err-clr: #ff5d5d;
  --suc-clr: #75dd88;
}

ul {
  list-style: none;
}

body {
  background-color: var(--prim-bkg-clr);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.material-symbols-outlined {
  font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 48;
}
