.post {
  margin-top: 0.5rem;
  width: 100%;
  max-width: 550px;
}

.post-header {
  padding: 0.25rem 0.5rem;
  margin-bottom: 0.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--sec-bkg-clr);
  border: 2px solid var(--prim-clr);
  border-radius: 5px;
}

.header-left {
  display: flex;
  align-items: center;
}

.header-pfp {
  height: 50px;
  width: 50px;
  border-radius: 99rem;
  border: 2px solid var(--prim-clr);
  object-fit: cover;
}

.header-username {
  margin-left: 0.25rem;
  background-color: var(--prim-clr);
  border-radius: 5px;
  padding: 0.25rem;
}

.header-right {
  display: flex;
  align-items: center;
}

.header-posted-time {
  margin-right: 0.25rem;
}

.header-menu {
  position: relative;
  background-color: var(--prim-clr);
  border-radius: 5px;
  cursor: pointer;
  padding: 0.25rem;
}

.header-options {
  flex-direction: column;
  border: 2px solid var(--prim-clr);
  position: absolute;
  border-radius: 5px;
  right: 0px;
  top: 32px;
}

.options-widget {
  background-color: var(--sec-bkg-clr);
  padding: 0.2rem 1rem;
  cursor: pointer;
  border-bottom: 2px solid var(--prim-clr);
}

.options-widget:last-child {
  border: none;
}

.post-body {
  background-color: var(--sec-bkg-clr);
  border: 2px solid var(--prim-clr);
  border-radius: 5px;
  padding: 1rem 2rem;
  max-height: 300px;
  overflow: hidden;
}

.post-text {
  line-height: 2rem;
  word-wrap: break-word;
}
.text-images {
  margin-bottom: 1rem;
}

.post-image {
  height: 100px;
  width: 100px;
  margin-right: 0.25rem;
  object-fit: cover;
  border: 2px solid var(--prim-clr);
  border-radius: 5px;
  cursor: pointer;
}

.post-footer {
  display: flex;
  justify-content: flex-end;
  padding: 0.25rem;
}

.post-show {
  border: none;
  padding: 0.5rem;
  border-radius: 5px;
  background-color: var(--prim-clr);
}

.post-show:hover {
  background-color: var(--sec-clr);
  cursor: pointer;
}
