.bears {
  display: flex;
  flex-direction: column;
}

.bears-card {
  margin-bottom: 2rem;
  padding: 2rem;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 3px solid var(--prim-clr);
  border-radius: 5px;
  background-color: var(--sec-bkg-clr);
}

.bears-card--pfp {
  margin-bottom: 3rem;
  height: 200px;
  width: 200px;
  object-fit: cover;
  object-position: bottom;
  border-radius: 100%;
  border: solid 3px var(--prim-clr);
}

.bears-card--username {
  margin-bottom: 0.5rem;
}

.bears-card--birthdate {
  margin-bottom: 3rem;
}

.bears-card--about {
  line-height: 2rem;
  font-weight: 400;
  word-wrap: break-word;
}

@media only screen and (min-width: 500px) {
  .bears {
    flex-direction: column;
    align-items: center;
  }
  .bears-card {
    margin: 1rem;
    width: 500px;
  }
}

@media only screen and (min-width: 1200px) {
  .bears {
    flex-direction: row;
    align-items: stretch;
  }
}
