.navigation {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--sec-bkg-clr) 3d;
  padding: 0.65rem;
  margin: 1rem;
  display: inline-block;
  border-radius: 100rem;
  border: solid 3px #ffbeb098;
}

.active {
  background-color: var(--prim-clr);
}

.navigation-menu-button {
  font-size: 3rem;
  padding: 0.35rem;
  border-radius: 100rem;
}
.navigation-links {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.navigation-link {
  color: var(--blk-clr);
  margin-top: 3rem;
  height: 3rem;
  width: 3rem;
  padding: 0.35rem;
  border-radius: 100rem;
}

.navigation-link:hover {
  background-color: var(--prim-clr);
}

@media only screen and (min-width: 740px) {
  .navigation-link:first-child {
    margin-top: 0;
  }
}
